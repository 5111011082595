import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'input-error',
    templateUrl: './input-error.component.html',
    styleUrls: ['./input-error.component.scss'],
    standalone: false
})
export class InputErrorComponent implements OnInit {

  @Input() text: string | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
