import {Directive, HostListener, inject} from '@angular/core';
import {EventTrackerService} from "../services/event-tracker.service";

@Directive({
    selector: 'button',
    standalone: false
})
export class ButtonClickListenerDirective {

  private readonly tracker = inject(EventTrackerService);

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    const buttonElement = this.findButtonElement(target);
    if (buttonElement) {
      const { id, innerText } = buttonElement;
      const dataTest = buttonElement.attributes.getNamedItem('data-test')?.value;
      this.tracker.trackEvent('User action', 'Button click', id || dataTest || innerText);
    }
  }

  private findButtonElement(element: HTMLElement): HTMLButtonElement | null {
    while (element && element.tagName !== 'BUTTON') {
      element = element.parentElement as HTMLElement;
    }
    return element as HTMLButtonElement;
  }
}
