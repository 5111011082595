import {Injectable} from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError
} from "rxjs";
import {Picture, PictureStatus} from "../../core/models/picture";
import * as Sentry from "@sentry/angular";
import {StoreService} from "./store.service";
import {CustomError} from "../../core/models/error";
import {AnalysisStatusEnum} from "../../core/models/analysis-status";
import {ApiService} from "../../core/api/api.service";
import {StepType} from "../../core/models/step";
import {ReferenceApiService} from "../../core/api/reference-api/reference-api.service";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {isEqual} from "lodash";
import {TyreAnalysisRequestDto} from "../../core/dto/tyre-analysis-request-dto";
import {GetAnalysisResponseDto, GetAnalysisTyreResponseDto} from "../../core/dto/get-analysis-response-dto";
import {TyreAnalysisResponseDto} from "../../core/dto/tyre-analysis-response-dto";
import {IndexedDBService} from "./indexed-db.service";
import moment, {duration, Duration, Moment} from "moment";
import {Utils} from "../utils/generics.utils";
import {DialogErrorData} from "../components/dialog-error/models/dialog-error";
import {DialogErrorService} from "../components/dialog-error/dialog-error.service";

@Injectable({
  providedIn: 'root'
})
export class PictureUploadService {

  private readonly obsQueue = new Subject<Observable<Picture>>();
  private readonly onOneUploadFinished$ = new Subject<number>();
  private onAllUploadsFinished$ = new BehaviorSubject(false);
  private cancelUpload$ = new Subject<void>();
  private uploadQueue: Picture[] = [];
  private _failedUploads: Picture[] = [];
  private queueCount = 0;
  private queueCountFinished = 0;
  private currentEstimatedTotalUploadTime: Duration = duration();
  private currentUploadTime: Moment = moment();
  private currentUploadTimesList: number[] = [];

  constructor(private storeService: StoreService,
              private api: ApiService,
              private referenceApi: ReferenceApiService,
              private indexedDB: IndexedDBService,
              private dialogService: DialogErrorService) {
    this.observeUploads();
    this.indexedDB.getAllPicturesByStatus(PictureStatus.UPLOADING).then((pictures) => {
      pictures = pictures || [];
      if (pictures.length && this.storeService.getLocalAnalysis()){
        Sentry.captureMessage('Started re-upload of pictures', {extra: {pictures: Utils.sanitizePictures(pictures)}});
      }
      pictures.forEach((picture) => this.uploadTyreAnalysisPicture(picture));
    });
  }

  private observeUploads() {
    this.obsQueue.pipe(
      tap(() => this.startUploadTimer()),
      mergeMap((obs: Observable<Picture>) => obs.pipe(
        catchError((picture: Picture) => this.onError(picture)),
        tap(() => this.updateUploadTimeEstimate())
      ))).subscribe((picture) => {
        this.onUploadSuccessful(picture);
        this.onUploadCompletion();
      }
    );
  }

  private onError(picture: Picture) {
    this.onUploadError(picture);
    this.onUploadCompletion();
    return EMPTY;
  }

  private startUploadTimer() {
    this.currentUploadTime = moment();
  }

  private updateUploadTimeEstimate() {
    const getSecondHalf = (arr: number[]) => {
      if (arr.length > 2) {
        const middleIndex = Math.floor(arr.length / 2);
        return arr.slice(middleIndex);
      } else {
        return arr;
      }
    }

    this.currentUploadTimesList.push(moment().diff(this.currentUploadTime));
    const secondHalf = getSecondHalf(this.currentUploadTimesList);
    const _seconds =
      ((secondHalf.reduce((sum, num) => sum + num, 0) / secondHalf.length)
        / 1000)
      * (this.uploadQueue.length - 1);
    this.currentEstimatedTotalUploadTime = duration({ seconds: Math.floor(_seconds) });
  }

  private onUploadCompletion() {
    this.queueCountFinished++;
    this.onOneUploadFinished$.next(this.queueCountFinished);
    this.uploadQueue.pop();
    if (this.uploadQueue.length !== 0)
      this.handleUpload(this.uploadQueue[this.uploadQueue.length - 1]);
  }

  private onUploadSuccessful(picture: Picture) {
    this.indexedDB.changePictureStatus(picture, PictureStatus.VALID);
    this.storeService.setPicture(picture);
  }

  private onUploadError(picture: Picture) {
    picture.status = PictureStatus.ERROR;
    this.storeService.setPicture(picture);
    this.failedUploads.push(picture);
  }

  uploadTyreAnalysisPicture(picture: Picture) {
    const samePictureInQueue = !!this.uploadQueue.find((p) => isEqual(p.step, picture.step));

    if (!samePictureInQueue) {
      if (!picture.data || !picture.data?.byteLength) {
        this.indexedDB.deletePicture(picture.step.toString());
        Sentry.captureException(new Error(CustomError.ABNORMAL_PICTURE_SIZE), {extra: {picture: Utils.sanitizePictures(picture)}});
        return;
      }

      this.indexedDB.addPictureIfNotPresent(picture);
      this.queueCount++;
      if (this.uploadQueue.length === 0) {
        this.handleUpload(picture);
      }
      this.uploadQueue.unshift(picture);
    }
  }

  private updateTyreAnalysis(picture: Picture) {
    const tyreAnalysisId = picture.uploadInfo?.id;
    if (tyreAnalysisId) {
      return this.referenceApi.updateTyreAnalysis(tyreAnalysisId, AnalysisStatusEnum.UPLOADED);
    } else {
      return throwError(() => new Error(CustomError.NO_TYRE_ANALYSIS));
    }
  }

  private handleUpload(picture: Picture) {
    const vehicleId = this.storeService.getVehicle().id;
    if (picture.data && vehicleId) {
      const subject = this.referenceApi.getAnalysis(vehicleId, this.storeService.getContext())
        .pipe(
          mergeMap((analysis) => this.handleAnalysis(picture, analysis, vehicleId)),
          catchError((error) => this.handlePushError(picture, error)),
          mergeMap(_ => this.updateTyreAnalysis(picture)),
          map(_ => picture),
          catchError((error) => {
            Sentry.captureException(new Error('Image upload error'), {extra: {error, picture: Utils.sanitizePictures(picture)}});
            return throwError(() => picture)
          }),
          takeUntil(this.cancelUpload$), // Cancel the upload when the cancelUpload$ subject emits
          finalize(() => {
          }))

          this.obsQueue.next(subject);
    } else {
      this.obsQueue.next(throwError(() => picture));
    }
  }

  private handleAnalysis(picture: Picture, analysis: GetAnalysisResponseDto, vehicleId: number, retry409?: boolean): Observable<HttpResponse<Response> | never> {
    const steps = picture.step.type === StepType.REFERENCE ? analysis.referenceTyres : analysis.wearTyres;
    const tyreAnalysis = steps.find((ta) => ta.position === picture.step.position);
    if (!tyreAnalysis) {
      return this.createTyreAnalysisAndUploadPicture(vehicleId, analysis.analysisId, picture, retry409);
    }
    if (tyreAnalysis.uploadUrl.fileName && tyreAnalysis.uploadUrl.url) {
      if (tyreAnalysis.status === AnalysisStatusEnum.STARTED) {
        this.setUploadInfo(picture, tyreAnalysis);
        return this.api.uploadFile(new File([picture.data!], tyreAnalysis.uploadUrl.fileName), tyreAnalysis.uploadUrl.url, picture);
      } else {
        Sentry.captureMessage("Ignore Tyre Analysis", {extra: {tyreAnalysis}});
        this.onUploadSuccessful(picture);
        this.onUploadCompletion();
        return EMPTY;
      }
    } else {
      return throwError(() => picture);
    }
  }

  private handlePushError(picture: Picture, error: HttpErrorResponse) {
    const vehicleId = this.storeService.getVehicle().id;
    if (error.status === 403 && vehicleId) {
      return this.referenceApi.getAnalysis(vehicleId, this.storeService.getContext()).pipe(switchMap((analysis) => {
        const steps = picture.step.type === StepType.REFERENCE ? analysis.referenceTyres : analysis.wearTyres;
        const tyreAnalysis = steps.find((ta) => ta.position === picture.step.position);
        if (tyreAnalysis) {
          this.setUploadInfo(picture, tyreAnalysis);
          if (picture.data && tyreAnalysis.tyreAnalysisId && tyreAnalysis.uploadUrl.fileName && tyreAnalysis.uploadUrl.url) {
            return this.api.uploadFile(new File([picture.data], tyreAnalysis.uploadUrl.fileName), tyreAnalysis.uploadUrl.url, picture);
          } else {
            return throwError(() => error);
          }
        } else {
          return throwError(() => new Error(CustomError.NO_TYRE_ANALYSIS));
        }
      }));
    }
    return throwError(() => error);
  }

  private createTyreAnalysisAndUploadPicture(vehicleId: number, analysisId: number, picture: Picture, retry409?: boolean) {
    return this.referenceApi.createTyreAnalysis(analysisId, new TyreAnalysisRequestDto('jpg', picture.step.position, picture.step.type))
      .pipe(
        catchError((error) => {
          if (error.status === 409) {
            if (retry409){
              Sentry.captureException(new Error('Synchronization failed, too many retries for tyre analysis creation'), {extra: {error, picture: Utils.sanitizePictures(picture), vehicleId, analysisId}});
              this.dialogService.show(new DialogErrorData('error.unknown.title'));
              return this.onError(picture);
            }
            Sentry.captureMessage("Tyre Analysis already exists", {extra: {error, vehicleId, analysisId, picture: Utils.sanitizePictures(picture)}});
            return of(true);
          }
          return throwError(() => error);
        }),
        tap((tyreAnalysis) => typeof tyreAnalysis !== 'boolean' ? this.setUploadInfo(picture, tyreAnalysis) : undefined),
        switchMap((value) => forkJoin([
          this.referenceApi.getAnalysis(vehicleId, this.storeService.getContext()),
          of(typeof value === 'boolean')
        ])),
        switchMap(([analysis, retry409]) => this.handleAnalysis(picture, analysis, vehicleId, retry409))
      );
  }

  private setUploadInfo(picture: Picture, tyreAnalysis: TyreAnalysisResponseDto | GetAnalysisTyreResponseDto) {
    let id: number | undefined;
    if ("id" in tyreAnalysis)
      id = tyreAnalysis.id;
    else if("tyreAnalysisId" in tyreAnalysis)
      id = tyreAnalysis.tyreAnalysisId;
    else
      id = picture.uploadInfo?.id;
    picture.uploadInfo = {
      id,
      uploadUrl: tyreAnalysis.uploadUrl
    }
    this.storeService.setPicture(picture);
  }

  get onAllPicturesUploaded() {
    return this.onAllUploadsFinished$.asObservable();
  }

  get onUpload() {
    return this.onOneUploadFinished$.asObservable();
  }

  isUploadInProgress() {
    return this.queueCountFinished < this.queueCount;
  }

  get uploadCount() {
    return this.queueCount;
  }

  get finishedUploadCount() {
    return this.queueCountFinished;
  }

  reset(endSession: boolean) {
    this.queueCount = 0;
    this.queueCountFinished = 0;
    this.currentUploadTimesList = [];
    this.currentEstimatedTotalUploadTime = duration();
    this.currentUploadTime = moment();
    this.failedUploads = [];
    if(endSession)
      this.onAllUploadsFinished$.next(false);
  }

  hasFailedUploads() {
    return this.failedUploads.length !== 0;
  }

  get failedUploadCount() {
    return this.failedUploads.length;
  }

  get failedUploads() {
    return this._failedUploads;
  }

  get estimatedUploadTime() {
    return this.currentEstimatedTotalUploadTime;
  }

  private set failedUploads(value: Picture[]) {
    this._failedUploads = value;
  }

  allUploadsFinished() {
    this.onAllUploadsFinished$.next(true);
  }

  cancelUpload() {
    this.reset(false);
    this.uploadQueue = [];
    this.cancelUpload$.next();
  }
}
