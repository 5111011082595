import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {DialogErrorService} from "./dialog-error.service";

@Component({
    selector: 'app-dialog-error',
    templateUrl: './dialog-error.component.html',
    styleUrls: ['./dialog-error.component.scss'],
    standalone: false
})
export class DialogErrorComponent implements OnInit {

  private service = inject(DialogErrorService);

  data$ = this.service.data$;
  @Output() buttonAction = new EventEmitter<any>();

  ngOnInit(): void {
  }

  buttonClick() {
    this.buttonAction.emit();
  }

}
