import {Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime, Subject, takeUntil} from 'rxjs';

@Directive({
    selector: '[debounceClick]',
    standalone: false
})
export class DebounceClickDirective implements OnInit, OnDestroy {

  @Output() debounceClick = new EventEmitter();

  private readonly onDestroy$ = new Subject();
  private clicks = new Subject();

  constructor() { }

  ngOnInit(): void {
    this.clicks
    .pipe(debounceTime(500),
          takeUntil(this.onDestroy$))
    .subscribe((e: any) => {
      this.debounceClick.emit(e);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
}

  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
