import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigService} from "../../../core/config";
import {IntegrationService} from "../../../core/integration/integration.service";
import {IntegrationAction} from "../../../core/models/integration-data";
import {CookiesConsentService} from "../cookies-consent/services/cookies-consent.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-initial',
    templateUrl: './initial.component.html',
    styleUrls: ['./initial.component.scss'],
    standalone: false
})
export class InitialComponent implements OnInit {

  @Output() onNext = new EventEmitter();
  @Input() isPro: boolean = false;
  logoHeight: number;
  canContinue = true;
  banner?: string;

   constructor(private config: ConfigService,
               private integration: IntegrationService,
               protected cookiesService: CookiesConsentService,
               private router: Router) {
     this.logoHeight = this.config.getWebapp().logoHeight * 1.12;
     this.banner = this.config.getWebapp().adsBanner;

     // if we came back from cookies view, skip and go to next step
     if (cookiesService.isOriginCookies() && cookiesService.hasChosenCookiePolicies()) {
       // hack to prevent ExpressionChangedAfterItHasBeenCheckedError,
       // else we have to implement this logic in every view that uses this component
       setTimeout(() => this.onNext.emit());
     }
  }

  ngOnInit() {
    this.integration.isStarted({action: IntegrationAction.LANDING_ACTION, data: {step: 0}});
  }

  onClick() {
    if (this.cookiesService.hasChosenCookiePolicies() && ((!this.isPro && this.canContinue) || (this.isPro))) {
      this.onNext.emit();
    } else if (this.isPro && !this.cookiesService.hasChosenCookiePolicies()) {
      this.router.navigate(['/cookies']);
    }
  }
}
