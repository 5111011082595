import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CdsSvgIconComponent} from "@carool1/ngx-carool-ds";

@Component({
    selector: 'app-banner',
    imports: [CommonModule, CdsSvgIconComponent],
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  @Input() banner?: string;
  showBanner = true;

}
