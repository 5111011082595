import {Directive, HostListener, inject, OnInit} from '@angular/core';
import {EventTrackerService} from "../services/event-tracker.service";
import {debounceTime, distinctUntilKeyChanged, Subject} from "rxjs";

interface KeyUpEvent {
  target: HTMLElement,
  value: string
}

@Directive({
    selector: 'input',
    standalone: false
})
export class InputListenerDirective implements OnInit {

  private readonly DEBOUNCE_TIME_MS = 2000;

  private readonly keyUp$ = new Subject<KeyUpEvent>();

  private readonly tracker = inject(EventTrackerService);

  ngOnInit(): void {
    this.keyUp$.pipe(
        debounceTime(this.DEBOUNCE_TIME_MS),
        distinctUntilKeyChanged('value')
    ).subscribe((event) => {
      this.trackInputEvent(event.target, 'keyup');
    })
  }

  @HostListener('focus', ['$event.target'])
  onFocus(target: HTMLElement) {
    this.trackInputEvent(target, 'focus');
  }

  @HostListener('blur', ['$event.target'])
  onBlur(target: HTMLElement) {
    this.trackInputEvent(target, 'blur');
  }

  @HostListener('keyup', ['$event.target'])
  onKeyUp(target: HTMLInputElement) {
    this.keyUp$.next({ target, value: target.value });
  }

  private trackInputEvent(target: HTMLElement, event: string) {
    const id = target.id;
    const dataTest = target.attributes.getNamedItem('data-test')?.value;

    this.tracker.trackEvent('User action', `Input ${event}`, id || dataTest);
  }
}
