import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from "../../../core/config";
import {TcuService} from "./tcu.service";
import {Subject, takeUntil} from "rxjs";
import {StoreService} from "../../services/store.service";
import {ConnectedUser} from "../../../core/models/connected-user";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem} from "../accordion/models/menu-item";

@Component({
    selector: 'app-tcu-dialog',
    templateUrl: './tcu-dialog.component.html',
    standalone: false
})
export class TcuDialogComponent implements OnInit, OnDestroy {

  private config = inject(ConfigService);
  private store = inject(StoreService);
  private tcu = inject(TcuService);
  private translate = inject(TranslateService);

  private readonly onDestroy$ = new Subject();

  cguUrl = this.config.getLegalsUrl();
  prvUrl = this.config.getPrivacyUrl();
  tcuVersion = this.config.getFeatures().ctuCurrentVersion;
  isPro = this.config.getWebapp().isProEnabled;
  dialogShown$ = this.tcu.dialogShown$;

  menuItems: MenuItem[] = [];
  private readonly icons = ['mail', 'primary-car', 'speed', 'driver-wheel-small', 'my_location']

  ngOnInit(): void {
    this.translate.get('cgu.menus').pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      const items: any = Object.values(value);

      for(const [i, value] of items.entries())
        this.menuItems[i] = { active: false, icon: this.icons[i], ...value};
    });
    // TODO: Public offline mode.
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  validate() {
    if (this.isPro) {
      if ((this.store.getUser() as ConnectedUser)?.userId) {
        this.tcu.setAcceptedTcu(this.tcuVersion).pipe(takeUntil(this.onDestroy$)).subscribe(_ => {
          this.tcu.hideTcuDialog();
        });
      } else {
        this.tcu.tcuAccepted();
        this.tcu.hideTcuDialog();
      }
    }
  }

  onMenuToggle(menuItem: MenuItem) {
    this.menuItems.forEach((item) => item.active = item === menuItem ? !item.active : false);
  }

}
