export enum CustomError {
  NO_SUCH_VEHICLE = 'NO_SUCH_VEHICLE',
  NO_VALID_ANALYSIS = 'NO_VALID_ANALYSIS',
  NO_SUCH_RESOURCE = 'NO_SUCH_RESOURCE',
  NO_SUCH_FLEET = 'NO_SUCH_FLEET',
  NOT_ENOUGH_RIGHTS = 'NOT_ENOUGH_RIGHTS',
  ONGOING_ANALYSIS = 'ONGOING_ANALYSIS',
  ONGOING_VEHICLE_ANALYSIS = 'ONGOING_VEHICLE_ANALYSIS',
  NO_ANALYSIS = 'NO_ANALYSIS',
  NO_TYRE_ANALYSIS = 'NO_TYRE_ANALYSIS',
  INVALID_LICENSE_PLATE = 'INVALID_LICENSE_PLATE',
  INVALID_USER_DATA = 'INVALID_USER_DATA',
  PICTURE_NETWORK_ERROR = 'PICTURE_NETWORK_ERROR',
  BACKEND_ERROR = 'BACKEND_ERROR',
  VEHICLE_ALREADY_ENROLLED = 'VEHICLE_ALREADY_ENROLLED',
  NO_SUCH_PROFILE = 'NO_SUCH_PROFILE',
  UNKNOWN = 'UNKNOWN',
  HAS_NO_GARAGES_AROUND = 'HAS_NO_GARAGES_AROUND',
  UNKNOWN_SCANNER_ERROR = 'UNKNOWN_SCANNER_ERROR',
  NO_SUCH_COMPANY = 'NO_SUCH_COMPANY',
  ABNORMAL_PICTURE_SIZE = 'ABNORMAL_PICTURE_SIZE',
  NO_CONTEXT_KEY = 'NO_CONTEXT_KEY',
  NO_APPOINTMENT_INFO = 'NO_APPOINTMENT_INFO',
  MISSING_PARAMETERS = 'MISSING_PARAMETERS',
  UNABLE_TO_TAKE_PICTURE = 'UNABLE_TO_TAKE_PICTURE',
  PROVIDER_TIME_SLOT_NOT_AVAILABLE = 'PROVIDER_TIME_SLOT_NOT_AVAILABLE',
  BAD_LICENSE = 'BAD_LICENSE',
  ANALYSIS_TOO_OLD_EXCEPTION = 'ANALYSIS_TOO_OLD_EXCEPTION',
  ANALYSIS_TOO_OLD = 'ANALYSIS_TOO_OLD'
}
