<app-dialog *ngIf="showCookies" [hasPadding]="false" @cookieDialog>
  <div class="flex flex-col gap-2 items-center dialog-cookies w-full max-w-[600px] mx-auto">
    <cds-svg-icon name="cookie" class="mt-6"></cds-svg-icon>
    <h2 class="text-center mt-1" [innerHTML]="'landing.cookies-dialog.title' | translate"></h2>
    <p class="text-lg">{{ 'landing.cookies-dialog.sub-title' | translate }}</p>
    <div class="px-6 mt-4 w-full">
      <div class="background px-4 py-3 flex gap-4 rounded-lg color w-full items-center">
        <cds-svg-icon name="bulb-big"></cds-svg-icon>
        <div class="flex flex-col gap-2 py-2">
          <b class="leading-5">{{ 'landing.cookies-dialog.infobox.title' | translate }}</b>
          <ul>
            <li class="list-item">{{ 'landing.cookies-dialog.infobox.listItem1' | translate }}</li>
            <li class="list-item">{{ 'landing.cookies-dialog.infobox.listItem2' | translate }}</li>
          </ul>
          <a routerLink="/cookies" [queryParams]="{ preferences: true }" (debounceClick)="showCookies = false">
            <b class="underline leading-5">{{ 'landing.cookies-dialog.infobox.customizeCookies' | translate }}</b>
          </a>
        </div>
      </div>
    </div>
    <div class="px-6 py-4 w-full">
      <div class="flex gap-2 w-full content-center text-lg">
        <button cdsButton appRipple id="refuse-cookies-btn" color="accent" (debounceClick)="onCookieClick(false)">{{ 'landing.cookies-dialog.buttons.refuse' | translate }}</button>
        <button cdsButton appRipple data-test="consent-btn" color="primary" (debounceClick)="onCookieClick(true)">{{ 'landing.cookies-dialog.buttons.accept' | translate }}</button>
      </div>
    </div>
  </div>
</app-dialog>
